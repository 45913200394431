/**
 * Les types d'environnement du portail entreprises
 */
const NeufsneufProfiles = {
  DEV: "dev",
  PROD: "prod",
};

/**
 * Les modals du portail entreprises
 */
const ModalTypes = {
  LOGOUT: "#modal-deconnexion",
  EXTERNAL_LINK: "#modal-external-link",
};

/**
 * Évènements du store
 */
const StoreKeys = {
  INFO_COMPTE: "infoCompteDto",
  HABILITATIONS: "habilitations",
  ETAT_DEMANDES: "etatDemandes",
  SELECTED_SIREN: "selectedSiren",
  SELECTED_SIREN_MODEL: "selectedSirenModel",
  SELECTED_PRMS: "selectedPrms",
  TACHES_TEMP: "tachesTemp",
  TACHES: "taches",
};

const TypeParcours = {
  BILAN_CONTINUITE: "bilans",
  FEUILLET_GESTION:"feuillets-gestion",
  CONTRATS: "contrats"
}

export { NeufsneufProfiles, ModalTypes, StoreKeys, TypeParcours };
