/**
 * Classe générique pour ouvrir une modal et réagir aux actions utilisateur : OK et NOK
 */
export default class ModalBase extends HTMLDivElement {
  /**
   * Initialise une nouvelle instance de type {@type ModalBase}.
   */
  constructor() {
    super();

    jQuery(this).on("show.bs.modal", this._onShowModal.bind(this));
    jQuery(this).on("hidden.bs.modal", this._onHideModal.bind(this));
    this.querySelectorAll(".accept-button").forEach(element=> { element.addEventListener("click", this._onAccept.bind(this))});
    this.querySelectorAll(".dismiss-button").forEach(element=> { element.addEventListener("click", this._onDismiss.bind(this))});
  }

  /**
   * Configurer le composant pour réagir aux changements de l'attribut display.
   * @return {string[]}
   */
  static get observedAttributes() {
    return ["display"];
  }

  /**
   * Callback appelé à chaque changement d'attribut.
   * Notamment utilisé pour ouvrir la modal lorsque l'attribut display passe à true.
   * @param {string} name Nom de l'attribut.
   * @param {*} oldValue Ancienne valeur.
   * @param {*} newValue Nouvelle valeur.
   */
  attributeChangedCallback(name, oldValue, newValue) {
    if (name === "display" && newValue === "true") {
      this._openModal();
    }
  }

  /**
   * Action pour ouvrir la modal.
   * @protected
   */
  _openModal() {
    jQuery(this).modal({
      backdrop: "static",
      keyboard: false,
    });
  }

  /**
   * Action pour fermer la modal.
   * @protected
   */
  _closeModal() {
    jQuery(this).modal("toggle");
  }

  /**
   * Action à surcharger pour réagir à l'ouverture de la modal
   * @protected
   */
  _onShowModal() {}

  /**
   * Action à surcharger pour réagir à la fermeture de la modal
   * @protected
   */
  _onHideModal() {}

  /**
   * Action à surcharger pour réagir au clic utilisateur OK.
   * @protected
   */
  _onAccept() {}

  /**
   * Action à surcharger pour réagir au clic utilisateur NOK.
   * @protected
   */
  _onDismiss() {}
}
