import { PageManager } from "../../services/page-manager";
import { VOTRE_COMPTE_NAVIGATION_SE_DECONNECTER } from "../../tag-plan";
import { TagCommanderManager } from "../../services/tag-commander-manager";
import IframeBase from "./iframe-base";
import { PageTypes } from "../../services/settings-loader";
import NeufsneufToast from "../common/neufsneuf-toast";

// TODO supprimer event afficher
// TODO Le portail ne doit pas recevoir un ordre ; il doit recevoir un état et en fonction de cet état il réagit
/**
 * La configuration pour réagir à l'event Afficher
 */
const AFFICHER_PAGE = {
  accueil: () => {
    PageManager.redirectTo(PageTypes.RACINE);
  },
  gestionCompte: () => {
    PageManager.redirectTo(PageTypes.GESTION_COMPTE);
  },
  associerEntreprise: () => {
    PageManager.redirectTo(PageTypes.ASSOCIER_ENTREPRISE);
  },
  vosEntreprises: () => {
    PageManager.redirectTo(PageTypes.VOS_ENTREPRISES);
  },
  gestionProfil: () => {
    PageManager.redirectTo(PageTypes.GESTION_PROFIL);
  },
  factures: () => {
    PageManager.redirectTo(PageTypes.FACTURES);
  },
  vosCanauxNumeriques: () => {
    PageManager.redirectTo(PageTypes.VOS_CANAUX_NUMERIQUES);
  },
  contratsAvenants: () => {
    PageManager.redirectTo(PageTypes.CONTRATS_AVENANTS);
  },
};

/**
 * Microapp générique
 */
export default class MicroApp extends IframeBase {
  /**
   * Les events génériques envoyés par les microapps au portail
   * @type {*}
   */
  pymEvents = {
    /**
     * Configurer la hauteur de l'iframe à la demande de la microapp
     * @param {string} height La hauteur de l'iframe
     */
    height: height => {
      this.style.height = height + "px";
    },
    /**
     * Scroll vers la node HTML à la demande de la microapp
     * @param {string} id L'identifiant HTML
     */
    scrollToElement: id => {
      // Scrolle jusqu'à un élément (à partir de son id) dans une iframe
      let element = this.firstChild.contentWindow.document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    },
    /**
     * Scroll en haut de la page à la demande de la microapp
     */
    scrollToTop: () => {
      window.scrollTo(0, 0);
    },
    /**
     * Scrolle jusqu'à la position donnée sur l'axe des ordonnées
     * Prend en compte la hauteur du breadcrumb
     * @param {string} yPos position sur l'axe des ordonnées
     */
    scrollToYposition: (yPos) => {
      let breadcrumbHeight = document.querySelector("neufsneuf-breadcrumb")?.getBoundingClientRect().height || 0;
      window.scrollTo({top: parseInt(yPos) + breadcrumbHeight, behavior: 'smooth'});
    },
    /**
     * Déconnexion de l'utilisateur à la demande de la microapp
     */
    deconnexionUser: () => {
      PageManager.logout();
      TagCommanderManager.tagClick(VOTRE_COMPTE_NAVIGATION_SE_DECONNECTER);
    },
    /**
     * Afficher une page à la demande de la microapp
     * @param {string} msg la page à afficher au format page.params
     */
    Afficher: msg => {
      const parts = msg.split(".");
      const key = parts[0];
      const value = parts[1];

      if (AFFICHER_PAGE[key]) {
        AFFICHER_PAGE[key](value);
      } else {
        console.error("MICRO_APP_AFFICHER", msg);
      }
    },
    /**
     * Action pour tester l'intégration entre le portail et la microapp
     */
    test: msg => {
      alert(msg);
    },
    /**
     * Affichage d'un toast lorsque l'utilisateur n'est pas habilité à effectuer une action
     */
    utilisateurNonHabilite: () => {
      NeufsneufToast.INSTANCE.setToast(
        "Vous n'êtes pas habilité à effectuer cette action",
        "true",
        "3000",
        "s",
        "info",
        "blanc",
        "neutre-700",
      );
      NeufsneufToast.INSTANCE.show();
    }
  };

  /**
   * Les events spécifiques à une microapp, à surcharger dans les classes héritant de {@type MicroApp}
   * @type {*}
   */
  childPymEvents = {};

  /**
   * Initialise une nouvelle instance de type {@type MicroApp}.
   */
  constructor() {
    super();
  }

  /**
   * Affiche l'iframe et ajoute les événements pymjs
   * @override
   */
  displayOnReady() {
    if (this.pymParent) {
      this.pymParent.remove();
    }
    this.pymParent = new pym.Parent(this.id, this.url, this.config);
    this.setPymEvents();
    // Permet de fixer la taille de l'iframe à 188px, pour l'affichage du loader lors du chargement de la microapp.
    this.children[0].height = "188px";
  }

  /**
   * Ajoute la gestion des événements pymjs
   */
  setPymEvents() {
    const pym_events = Object.assign({}, this.pymEvents, this.childPymEvents);
    for (const key in pym_events) {
      if (pym_events.hasOwnProperty(key)) {
        this.pymParent.onMessage(key, msg => {
          console.log("MICRO_APP", key, msg);
          pym_events[key](msg);
        });
      }
    }
  }

  /**
   * Envoie un message pymjs
   */
  pymSendMessage(key, value) {
    if (typeof this.pymParent !== "undefined") {
      this.pymParent.sendMessage(key, value);
    }
  }
}
