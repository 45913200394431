import { StoreManager } from "./services/store-manager";
import { ParcoursController } from "./services/parcours-controller";
import { ComponentHelper } from "./services/component-helper";
import { NeufsneufProfiles } from "./services/enums";
import { neufsneufSettings } from "./services/settings-loader";
import { PageManager } from "./services/page-manager";
import { TagCommanderManager } from "./services/tag-commander-manager";

configuration();

if (PageManager.current.tag) {
  TagCommanderManager.tagPage(PageManager.current.tag);
}

/**
 * Permet de:
 * - Charger les infos utilisateurs.
 * - Gérer les parcours forcés au chargement de la page.
 * - Initialiser le Store.
 * - Afficher les microApps.
 *
 */
ParcoursController.loadUserInstance().then(userInstance => {
  if (ParcoursController.redirectionParcours(userInstance)) {
    return;
  }

  // Configuration du store
  StoreManager.initialize(userInstance);

  // Construction du reste de la page suite au chargement de l'utilisateur
  ComponentHelper.displayMicroapps();
});

/**
 * Configuration
 */
function configuration() {
  if (neufsneufSettings.envs.profile === NeufsneufProfiles.PROD) {
    // Désactiver les console log et warn en prod
    window.console.log = function () {};
    window.console.warn = function () {};
  }
}
