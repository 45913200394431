import { StoreManager } from "./store-manager";

class NeufsneufHabilitationsManager {
  /**
   * Vérifie que l'utilisateur possède une certaine fonction sur un siren
   * @param siren {string} Le siren
   * @param fonction {string} Le code fonction défini dans {@link CodeFonction}
   */
  verifierFonctionHabilitation(siren, fonction) {
    return StoreManager.habilitations.find(hab => hab.siren === siren)?.fonctions.includes(fonction) || false;
  }

  /**
   * Vérifie que l'utilisateur possède une certaine fonction sur au moins une de ses habilitations
   * @param fonction {string} Le code fonction défini dans {@link CodeFonction}
   */
  verifierFonction(fonction) {
    return StoreManager.habilitations.some(hab => hab.fonctions.includes(fonction));
  }

  /**
   * Retourne transforme les habilitations en une liste d'éléments lnc-dropdown-menu-option
   * @return {DropdownMenuOptionModel[]}
   */
  mapHabilitations() {
    if (!StoreManager.habilitations) {
      return [];
    }

    return StoreManager.habilitations.map(element => ({
      value: element.siren,
      title: element.raisonSociale,
      subtitle: element.siren.match(/.{1,3}/g).join(" "),
    }));
  }

  /**
   * Retourne la première valeur du lnc-dropdown-menu-siren possédant comme sirenTitulaire le SIREN passé en paramètre
   * @param siren {string} le siren à rechercher parmi les valeurs du lnc-dropdown-menu-siren
   * @return {string} objet stringifié de type {@link SirenModel}
   */
  getSirenModel(siren) {
    let sirenModel = JSON.stringify({
      sirenTitulaire: StoreManager.habilitations[0].siren,
      sirenBeneficiaire: StoreManager.habilitations[0].siren,
    });

    StoreManager.habilitations.some(habilitation => {
      if (habilitation.siren === siren) {
        sirenModel = JSON.stringify({
          sirenTitulaire: habilitation.siren,
          sirenBeneficiaire: habilitation.siren,
        });
        return true;
      }

      if (habilitation.autorisations?.length > 0) {
        const autorisation = habilitation.autorisations.find(autorisation => autorisation.siren === siren);
        if (autorisation) {
          sirenModel = JSON.stringify({
            sirenTitulaire: autorisation.siren,
            sirenBeneficiaire: habilitation.siren,
          });
          return true;
        }
      }

      return false;
    });

    return sirenModel;
  }
}

const HabilitationsManager = new NeufsneufHabilitationsManager();
export { HabilitationsManager };
