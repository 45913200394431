import { PageManager } from "../../services/page-manager";

/**
 * Composant pour le fil d'Ariane du portail
 */
export default class NeufsneufBreadcrumbs extends HTMLElement {

  /**
   * Singleton
   * @type {NeufsneufBreadcrumbs} l'insance du singleton
   */
  static INSTANCE = null;

  /**
   * Initialise une nouvelle instance de type {@type NeufsneufBreadcrumbs}
   */
  constructor() {
    super();
    NeufsneufBreadcrumbs.INSTANCE = this;
    if (PageManager.current.breadcrumbs) {
      this.buildBreadcrumb(PageManager.current.breadcrumbs);
    }
  }

  /**
   * Construction du fil d'Ariane
   * @param {BreadcrumbsConfig[]} config La configuration du fil d'Ariane
   */
  buildBreadcrumb(config) {
    this.querySelector("lnc-breadcrumbs").setAttribute("data", JSON.stringify(config));
  }
}
