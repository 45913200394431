import { mesTachesService } from "../../api-client";
import { StatutTache } from "../../api-client/enums";
import { ComponentHelper } from "../../services/component-helper";
import { ModalTypes, StoreKeys } from "../../services/enums";
import { PageManager } from "../../services/page-manager";
import { HeaderMenuData, NavbarData, neufsneufSettings } from "../../services/settings-loader";
import { StoreManager } from "../../services/store-manager";
import { TacheManager } from "../../services/tache-manager";
import { TagCommanderManager } from "../../services/tag-commander-manager";
import * as TagPlan from "../../tag-plan";
import {
  GESTION_COMPTE_PAGE_AUTORISATION_CONSEILLER_ENTREPRISE,
  RECHERCHER_COMPTEURS_POPIN_PRISE_EN_COMPTE_TELECHARGEMENT,
  RECHERCHER_COMPTEURS_RELANCER,
  RECHERCHER_COMPTEURS_TELECHARGER,
  VOTRE_COMPTE_NAVIGATION_CHANGER_PROFIL,
  VOTRE_COMPTE_NAVIGATION_GERER_COMPTE,
  VOTRE_COMPTE_NAVIGATION_SE_DECONNECTER,
} from "../../tag-plan";
import NeufsneufToast from "./neufsneuf-toast";

/**
 * Composant pour le header du portail
 * TODO un composant dédié à la barre des taches
 * TODO un composant dédié à la barre de menu
 */
export default class NeufsneufHeader extends HTMLElement {
  /**
   * Composant lnc header-entreprise
   */
  lncHeaderEntreprise;

  /**
   * Initialise une nouvelle instance de type {@type NeufsneufHeader}.
   */
  constructor() {
    super();
    this.lncHeaderEntreprise = this.querySelector("lnc-header-entreprise");

    if (this.lncHeaderEntreprise) {
      this.lncHeaderEntreprise.addEventListener("selectionChanged", this._onClickMenu.bind(this));
      this.lncHeaderEntreprise.addEventListener("taskItemClick", this._onTaskItemClick.bind(this));
      this.lncHeaderEntreprise.addEventListener("taskErrorButtonClick", () => {
        NeufsneufToast.INSTANCE.toastme("Le service est indisponible, veuillez réessayer plus tard");
      });
      this.lncHeaderEntreprise.addEventListener("openOverlay", () => {
        TagCommanderManager.tagPage(RECHERCHER_COMPTEURS_POPIN_PRISE_EN_COMPTE_TELECHARGEMENT);
      });
      this._loadDataNavbar();
      this._loadDataHeaderMenu();

      StoreManager.subscribe(StoreKeys.INFO_COMPTE, this._onInfoCompteChanged.bind(this));
      StoreManager.subscribe(StoreKeys.NB_ETAT_DEMANDES, this._onNbDemandesRefuseesOuValideesChanged.bind(this));
      StoreManager.subscribe(StoreKeys.TACHES, this._onTachesChanged.bind(this));
    }
  }

  /**
   * Methode pour charger les donnees du compte
   * Action sur le changement de valeur pour la clé INFO_COMPTE
   * @param infoCompteDto {InfoCompteDto} les informations de la personne connecté
   * @private
   */
  _onInfoCompteChanged(infoCompteDto) {
    this.lncHeaderEntreprise.setAttribute("username", infoCompteDto.prenom + " " + infoCompteDto.nom);
    if (
      !infoCompteDto.dateCGU ||
      infoCompteDto.dateCGU.getTime() < neufsneufSettings.last_cgu_update.getTime() ||
      !infoCompteDto.compteFinalise
    ) {
      if (this.querySelector("lnc-navbar")) {
        this.querySelector("lnc-navbar").style.display = "none";
      }
    }
  }

  /**
   * Methode de désactivation du menu
   * Action sur le changement de valeur pour la clé NB_ETAT_DEMANDES
   * @param etatDemandes {number,number,number} le nombre de demande
   * @private
   */

  _onNbDemandesRefuseesOuValideesChanged(etatDemandes) {
    if (etatDemandes.validees === 0) {
      Object.values(NavbarData).forEach(menu => (menu.disabled = true));
      if (etatDemandes.refusees > 0) {
        Object.values(NavbarData).forEach(menu => (menu.disabled = menu.id !== "GESTION_VOTRE_ENTREPRISE"));
      }
      this._loadDataNavbar();
    }
  }

  /**
   * Méthode pour charger la liste des tâches de l'utilisateur
   * Action sur le changement de valeur pour la clé TACHES
   * @param taches {TacheGroupDto} liste des tâches
   * @private
   */
  _onTachesChanged(taches) {
    const taskItems = taches.liste.map(tache => {
      return {
        id: tache.id,
        label: tache.libelle,
        status: tache.statut,
        typeTache: tache.type,
      };
    });

    const taskGroupModel = {
      statusOk: taches.statutOk,
      tasks: taskItems,
    };

    this.lncHeaderEntreprise.setAttribute("data-task-group", JSON.stringify(taskGroupModel));
  }

  /**
   * Action sur le changement de selection du menu
   * Récupération de l'objet défini dans headernavbarConfig à l'aide de l'id de l'item sélectionné
   * Envoi d'un tag et ouverture de la page associée à l'onglet, s'ils sont renseignés
   * @param selectionEvent {NavbarEvent} l'id de l'item selectionné
   * @private
   */
  _onClickMenu(selectionEvent) {
    let childrenItem = NavbarData[selectionEvent.detail.parentId]?.children[selectionEvent.detail.childId];
    if (childrenItem) {
      if (childrenItem.tag && TagPlan[childrenItem.tag]) {
        TagCommanderManager.tagClick(TagPlan[childrenItem.tag]);
      } else {
        console.warn(`Pas de Tag associé à l'onglet ${childrenItem.label}`);
      }
    } else {
      this._onClickMenuCompte(selectionEvent);
    }
  }

  /**
   * Réaction au clic sur le composant lnc-header-menu-entreprise
   * @param event Event l'évènement de clic
   * @private
   */
  _onClickMenuCompte(event) {
    const dataId = event.detail;
    if (dataId) {
      switch (dataId) {
        case "gestion-compte":
          TagCommanderManager.tagClick(VOTRE_COMPTE_NAVIGATION_GERER_COMPTE);
          break;
        case "changer-profil":
          TagCommanderManager.tagClick(VOTRE_COMPTE_NAVIGATION_CHANGER_PROFIL);
          break;
        case "support-agent":
          TagCommanderManager.tagClick(GESTION_COMPTE_PAGE_AUTORISATION_CONSEILLER_ENTREPRISE);
          break;
        case "se-deconnecter":
          TagCommanderManager.tagClick(VOTRE_COMPTE_NAVIGATION_SE_DECONNECTER);
          ComponentHelper.openModal(ModalTypes.LOGOUT);
          break;
        default:
          console.log(`Pas d'action pour ${dataId}`);
          break;
      }
    }
  }

  /**
   * Methode pour charger les donnees
   * mise en evidence de l'élément du menu correspondant à la page courante
   * et update du menu
   * @private
   */
  _loadDataNavbar() {
    Object.values(NavbarData).forEach(menu => {
      if (menu.url === PageManager.current.path) {
        menu.isCurrentPage = true;
      }

      for (var id = 0; id < menu.children?.length; id++) {
        /**
         * La configuration du sous menu
         * @type {Children}
         */
        const sousMenu = menu.children[id];
        if (sousMenu.masquer) {
          menu.children.splice(id, 1);
          id--;
        }
        if (sousMenu.url === PageManager.current.path) {
          sousMenu.isCurrentPage = true;
        }
        if (menu.id === HeaderMenuData["CHANGER_PROFIL"].id) {
          // l'URL devrait être la bonne dans le json et pas construite avec du code
          menu.url = neufsneufSettings.url.compte_client_externe + "/" + menu.url;
        }
      }
    });

    this.lncHeaderEntreprise.setAttribute("data-navbar", JSON.stringify(NavbarData));
  }

  /**
   * Methode pour charger les donnees
   * mise en evidence de l'élément du menu header correspondant à la page courante
   * et update du menu
   * @private
   */
  _loadDataHeaderMenu() {
    Object.values(HeaderMenuData).forEach(menu => {
      if (menu.url === PageManager.current.path) {
        menu.isCurrentPage = true;
      }
    });

    this.lncHeaderEntreprise.setAttribute("data-menu-compte", JSON.stringify(HeaderMenuData));
  }

  /**
   * Callback au clic du bouton télécharger ou relancer dans le lnc-tasks
   * Si le statut de la tache est à 'TERMINE' alors on télécharge
   * Si le statut de la tache est à 'ECHOUE' alors on relance
   * @param event {CustomEvent<TaskMenuEvent>}
   * @private
   */
  _onTaskItemClick(event) {
    const tache = event.detail;
    if (tache.status === StatutTache.TERMINE) {
      mesTachesService
        .telechargerTache(StoreManager.cnAlex, tache.id)
        .then()
        .catch(erreur => {
          NeufsneufToast.INSTANCE.toastme("Le téléchargement a échoué, veuillez réessayer plus tard");
        });
      TagCommanderManager.tagClick(RECHERCHER_COMPTEURS_TELECHARGER);
    } else if (tache.status === StatutTache.ECHOUE) {
      TacheManager.relancer(tache.id)
        .then()
        .catch(erreur => {
          NeufsneufToast.INSTANCE.toastme("La génération du fichier a échoué, veuillez réessayer plus tard");
        });
      TagCommanderManager.tagClick(RECHERCHER_COMPTEURS_RELANCER);
    }
  }
}
