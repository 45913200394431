import ModalBase from './modal-base';

/**
 * La modal de confirmation d'export et de consultation des tâches en cours
 */
export default class ModalSearchExportSuccess extends ModalBase {
  /**
   * Initialise une nouvelle instance de type {@type ModalSearchExportSuccess}.
   */
  constructor() {
    super();
  }

  /**
   * Action pour réagir au clic "Consulter vos tâches".
   * @override
   */
  _onAccept() {
    //TODO tagCommander ?
    // Ouvre la liste des tâches au clic sur le bouton de la popin
    document.querySelector('lnc-header-entreprise lnc-task lnc-header-button')?.click();
    this._closeModal();
  }

  /**
   * Action pour réagir au clic "Fermer".
   * @override
   */
  _onDismiss() {
    //TODO tagCommander ?
    this._closeModal();
  }
}
