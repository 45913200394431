import NeufsneufBreadcrumbs from "./common/neufsneuf-breadcrumbs";
import NeufsneufFooter from "./common/neufsneuf-footer";
import NeufsneufHeader from "./common/neufsneuf-header";
import NeufsneufMicroapp from "./common/neufsneuf-microapp";
import NeufsneufToast from "./common/neufsneuf-toast";
import EncartAdmin from "./encart-admin";
import IframeBase from "./micro-apps/iframe-base";
import MicroApp from "./micro-apps/micro-app-base";
import CompteClientMicroApp from "./micro-apps/micro-app-compte-client";
import EntreprisesMicroApp from "./micro-apps/micro-app-entreprises";
import ModalExternalLink from "./modal/modal-extenal-link";
import ModalLogout from "./modal/modal-logout";

/**
 * Définition des custom elements
 */
// Common
customElements.define("neufsneuf-header", NeufsneufHeader);
customElements.define("neufsneuf-breadcrumb", NeufsneufBreadcrumbs);
customElements.define("neufsneuf-microapp", NeufsneufMicroapp);
customElements.define("neufsneuf-footer", NeufsneufFooter);
customElements.define("neufsneuf-toast", NeufsneufToast);

// Iframe et microapps
customElements.define("micro-app-iframe-base", IframeBase, { extends: "div" });
customElements.define("micro-app", MicroApp, { extends: "div" });
customElements.define("micro-app-compte-client", CompteClientMicroApp, { extends: "div" });
customElements.define("micro-app-entreprises", EntreprisesMicroApp, { extends: "div" });
customElements.define("encart-admin", EncartAdmin, { extends: "div" });

// Modals
customElements.define("modal-logout", ModalLogout, { extends: "div" });
customElements.define("modal-external-link", ModalExternalLink, { extends: "div" });
