// Plan de taggage b2b

export const GESTION_COMPTE_PAGE_AUTORISATION_CONSEILLER_ENTREPRISE = {
  chapitre_1: "gestion_de_compte",
  chapitre_2: "assistance_conseiller",
  page: "assistance_conseiller_entreprise",
  type: "navigation",
};

export const GESTION_COMPTE_ACTION_AUTORISER_ENEDIS_ACCEDER_MON_COMPTE_CLIENT_ENTREPRISE = {
  chapitre_1: "gestion_de_compte",
  chapitre_2: "assistance_conseiller",
  page: "j_autorise_enedis_a_acceder_a_mon_compte_client_entreprise",
  type: "action",
};

export const GESTION_COMPTE_ACTION_EMPECHER_ENEDIS_ACCEDER_MON_COMPTE_CLIENT_ENTREPRISE = {
  chapitre_1: "gestion_de_compte",
  page: "assistance_conseiller_entreprise",
};

export const GESTION_COMPTE_PAGE_ASSISTANCE_CONSEILLER_ENTREPRISE = {
  chapitre_1: "gestion_de_compte",
  chapitre_2: "assistance_conseiller",
  page: "j_empeche_enedis_d_acceder_a_mon_compte_client_entreprise",
};

export const VALIDATION_CGU_ACTION_ACCEPTER = {
  chapitre_1: "validation_cgu",
  page: "accepter",
  type: "action",
};

export const VALIDATION_CGU_ACTION_REFUSER = {
  chapitre_1: "validation_cgu",
  page: "refuser",
  type: "action",
};

export const FINALISATION_PROFIL_ACTION_VALIDER_PROFIL_ENTREPRISE = {
  chapitre_1: "finalisation_profil",
  page: "valider_le_profil_entreprise",
  type: "action",
};

export const FINALISATION_PROFIL_PAGE_INDISPONIBILITE_SERVICE_ENTREPRISE = {
  chapitre_1: "finalisation_profil",
  page: "indisponibilite_service_entreprise",
  type: "action",
};

export const VOTRE_COMPTE_NAVIGATION_GERER_COMPTE = {
  chapitre_1: "votre_compte",
  page: "gerer_votre_compte",
  type: "navigation",
};

export const VOTRE_COMPTE_NAVIGATION_CHANGER_PROFIL = {
  chapitre_1: "votre_compte",
  page: "changer_de_profil",
  type: "navigation",
};

export const VOTRE_COMPTE_NAVIGATION_SE_DECONNECTER = {
  chapitre_1: "votre_compte",
  page: "se_deconnecter",
  type: "navigation",
};

export const PAGE_FINALISATION_PROFIL_ENTREPRISE = {
  page: "finalisation_profil_entreprise",
};

export const PAGE_ACCUEIL = {
  page: "accueil",
};

export const PAGE_GESTION_COMPTE = {
  page: "gestion_de_compte",
};

export const PAGE_VALIDATION_CGU = {
  chapitre_1: "validation_cgu",
  page: "validation_cgu",
};

export const NAVIGATION_CARTO_CAPACITES_RESEAU = {
  chapitre_1: "header",
  chapitre_2: "menu",
  chapitre_3: "votre_espace_raccordement",
  page: "cartographie_des_capacites_du_reseau",
  type: "navigation",
};

export const VOTRE_ENTREPRISE = {
  chapitre_1: "header",
  chapitre_2: "menu",
  chapitre_3: "gestion_de_votre_entreprise",
  page: "votre_entreprise",
  type: "navigation",
};

export const HABILITATION_DE_VOS_UTILISATEURS = {
  chapitre_1: "header",
  chapitre_2: "menu",
  chapitre_3: "gestion_de_votre_entreprise",
  page: "habilitation_de_vos_utilisateurs",
  type: "navigation",
};

export const VOS_CANAUX_NUMERIQUES = {
  chapitre_1: "header",
  chapitre_2: "menu",
  chapitre_3: "gestion_de_votre_entreprise",
  page: "vos_canaux_numeriques",
  type: "navigation",
};

export const VOS_AUTORISATIONS = {
  chapitre_1: "header",
  chapitre_2: "menu",
  chapitre_3: "gestion_de_votre_entreprise",
  page: "vos_autorisations",
  type: "navigation",
};

export const VOS_INTERLOCUTEURS = {
  chapitre_1: "header",
  chapitre_2: "menu",
  chapitre_3: "gestion_de_votre_entreprise",
  page: "vos_interlocuteurs",
  type: "navigation",
};

export const VOS_COMPTEURS = {
  chapitre_1: "header",
  chapitre_2: "menu",
  chapitre_3: "vos_donnees_energetiques",
  page: "vos_compteurs",
  type: "navigation",
};

export const VOS_API = {
  chapitre_1: "header",
  chapitre_2: "menu",
  chapitre_3: "vos_donnees_energetiques",
  page: "vos_api",
  type: "navigation",
};

export const VOS_ABONNEMENTS = {
  chapitre_1: "header",
  chapitre_2: "menu",
  chapitre_3: "vos_donnees_energetiques",
  page: "vos_abonnements",
  type: "navigation",
};

export const VISUALISER_VOS_MESURES = {
  chapitre_1: "header",
  chapitre_2: "menu",
  chapitre_3: "vos_donnees_energetiques​",
  page: "visualiser_vos_mesures",
  type: "navigation",
};

export const FACTURES = {
  chapitre_1: "header",
  chapitre_2: "menu",
  chapitre_3: "vos_documents",
  page: "vos_factures",
  type: "navigation",
};

export const CARTOGRAPHIE_DES_CAPACITES_DU_RESEAU = {
  chapitre_1: "header",
  chapitre_2: "menu",
  chapitre_3: "votre_espace_raccordement",
  page: "cartographie_des_capacites_du_reseau",
  type: "navigation",
};

export const VOS_SIMULATIONS_DE_RACCORDEMENT = {
  chapitre_1: "header",
  chapitre_2: "menu",
  chapitre_3: "votre_espace_raccordement",
  page: "vos_simulations_de_raccordement",
  type: "navigation",
};

export const VOS_DEMANDES_DE_RACCORDEMENT = {
  chapitre_1: "header",
  chapitre_2: "menu",
  chapitre_3: "votre_espace_raccordement",
  page: "vos_demandes_de_raccordement",
  type: "navigation",
};

export const CONTRATS_ET_AVENANTS = {
  chapitre_1: "header",
  chapitre_2: "menu",
  chapitre_3: "vos_documents",
  page: "contrats_et_avenants",
};

export const BILANS_CONTINUITE_DE_FOURNITURE = {
  chapitre_1: "header",
  chapitre_2: "menu",
  chapitre_3: "vos_documents",
  page: "bilans_continuite_de_fourniture",
};

export const FEUILLETS_DE_GESTION = {
  chapitre_1: "header",
  chapitre_2: "menu",
  chapitre_3: "vos_documents",
  page: "feuillets_de_gestion",
};

export const MENTIONS_LEGALES = {
  chapitre_1: "footer",
  page: "mentions_legales",
  type: "navigation",
};

export const CGU = {
  chapitre_1: "footer",
  page: "cgu",
  type: "navigation",
};

export const DONNEES_PERSONNELLES = {
  chapitre_1: "footer",
  page: "donnees_personnelles",
  type: "navigation",
};

export const POLITIQUE_DE_COOKIES = {
  chapitre_1: "footer",
  page: "politique_de_cookies",
  type: "navigation",
};

export const GERER_VOS_COOKIES = {
  chapitre_1: "footer",
  page: "gerer_vos_cookies",
  type: "navigation",
};

export const ACCESSIBILITE = {
  chapitre_1: "footer",
  page: "accessibilite",
  type: "navigation",
};

export const PLAN_DU_SITE = {
  chapitre_1: "footer",
  page: "plan_du_site",
  type: "navigation",
};

export const AIDE_ET_CONTACT = {
  chapitre_1: "footer",
  page: "aide_et_contact",
  type: "navigation",
};

export const POPIN_GENERATION_NOUVELLE_CLE = {
  chapitre_1: "gestion_de_votre_entreprise",
  chapitre_2: "Vos_canaux_numeriques",
  chapitre_3: "information",
  page: "popin_generation_nouvelle_cle",
};

export const SUPPRESSION_DU_CANAL_NUMERIQUE = {
  chapitre_1: "gestion_de_votre_entreprise",
  chapitre_2: "Vos_canaux_numeriques",
  chapitre_3: "information",
  page: "suppression_du_canal_numerique",
};

export const RECHERCHER_COMPTEURS_TELECHARGER = {
  chapitre_1: "vos_donnees_energetiques",
  chapitre_2: "vos_compteurs_prm",
  chapitre_3: "taches_en_cours",
  page: "telecharger",
  type: "download",
};

export const RECHERCHER_COMPTEURS_RELANCER = {
  chapitre_1: "vos_donnees_energetiques",
  chapitre_2: "vos_compteurs_prm",
  chapitre_3: "taches_en_cours",
  page: "relancer",
  type: "action",
};

export const RECHERCHER_COMPTEURS_POPIN_CONFIRMATION_DEMANDE = {
  chapitre_1: "vos_donnees_energetiques",
  chapitre_2: "vos_compteurs_prm",
  chapitre_3: "popin",
  page: "confirmation_demande",
};

export const RECHERCHER_COMPTEURS_POPIN_PRISE_EN_COMPTE_TELECHARGEMENT = {
  chapitre_1: "vos_donnees_energetiques",
  chapitre_2: "vos_compteurs_prm",
  chapitre_3: "popin_prise_en_compte_telechargement",
  page: "consulter_vos_taches",
  type: "action",
};

export const CATALOGUE_API_INDISPONIBLE = {
  chapitre_1: "vos_donnees_energetiques",
  chapitre_2: "decouvrir_les_autres_services",
  chapitre_3: "vos_api",
  page: "erreur_lors_du_chargement_iframeapi",
};

export const ACCES_GERER_AUTORISATIONS = {
  chapitre_1: "fiche_compteur",
  chapitre_2: "gerer_le_partage_des_donnees",
  chapitre_3: "",
  page: "gerer_les_autorisations",
};

export const ENREGISTREMENT_CONSOMMATION_HORAIRE_CLIC_ANNULER_OPPOSITION = {
  chapitre_1: "vos_donnees_energetiques",
  chapitre_2: "vos_compteurs_prm",
  chapitre_3: "fiche_compteur_prm",
  page: "annuler_sopposer_a_lenregistrement",
};

export const ENREGISTREMENT_CONSOMMATION_HORAIRE_CLIC_CONFIRMER_OPPOSITION = {
  chapitre_1: "vos_donnees_energetiques",
  chapitre_2: "vos_compteurs_prm",
  chapitre_3: "fiche_compteur_prm",
  page: "confirmer_sopposer_a_lenregistrement",
};

export const ENREGISTREMENT_CONSOMMATION_HORAIRE_CLIC_OPPOSITION_IMPOSSIBLE = {
  chapitre_1: "vos_donnees_energetiques",
  chapitre_2: "vos_compteurs_prm",
  chapitre_3: "fiche_compteur_prm",
  page: "popin_annuler",
};

export const CLICK_PARAMETRER_VOS_ABONNEMENTS = {
  chapitre_1: "vos_donnees_energetiques",
  chapitre_2: "vos_abonnements",
  chapitre_3: "Gestion_des_publications_recurrentes",
  page: "parametrer_vos_abonnements",
  type: "action",
};

export const ERREUR_TELECHARGEMENT_FEUILLET = {
  chapitre_1: "vos_documents",
  chapitre_2: "feuillet_de_gestion",
  chapitre_3: "erreur",
  page: "erreur_telechargement_feuillet",
};

export const ERREUR_TELECHARGEMENT_CONTRAT = {
  chapitre_1: "vos_documents",
  chapitre_2: "contrats_et_avenants",
  chapitre_3: "erreur",
  page: "erreur_telechargement_contrat",
};

export const ERREUR_TELECHARGEMENT_BILAN = {
  chapitre_1: "vos_documents",
  chapitre_2: "bilan_de_continuite",
  chapitre_3: "erreur",
  page: "erreur_telechargement_bilan",
};

export const CHARGEMENT_POPIN_PREMIERE_CONNEXION = {
  niveau_2: "5",
  chapitre_1: "Accueil_première_connexion",
  chapitre_2: "parcours_guide",
  chapitre_3: "etape_0",
  page: "popin_de_premiere_connexion",
}

export const CLICK_POPIN_PREMIERE_CONNEXION_PAS_MAINTENANT = {
  niveau_2: "5",
  chapitre_1: "Accueil_première_connexion",
  chapitre_2: "parcours_guide",
  chapitre_3: "etape_0",
  page: "pas_maintenant",
  type: "action",
}

export const CLICK_POPIN_PREMIERE_CONNEXION_LANCER_PARCOURS_GUIDE = {
  niveau_2: "5",
  chapitre_1: "Accueil_première_connexion",
  chapitre_2: "parcours_guide",
  chapitre_3: "etape_0",
  page: "lancer_le_parcours_guide",
  type: "action",
}
export const CLICK_ACCEDER_TUTORIELS = {
  chapitre_1: "page_accueil",
  niveau_2: "5",
  page: "consulter_tutoriels_disponibles",
  type: "action",
};

export const CLICK_ACCEDER_VOS_COMPTEURS = {
  chapitre_1: "page_accueil",
  niveau_2: "5",
  page: "raccourci_fonctionnalite_vos_compteurs",
  type: "action",
};

export const CLICK_ACCEDER_VOS_MESURES = {
  chapitre_1: "vos_donnees_energetiques",
  chapitre_2: "visualiser_vos_mesures",
  chapitre_3: "page_d_accueil",
  niveau_2: "5",
  page: "acceder_visualiser_vos_mesures",
  type: "action",
};

export const CLICK_AUTRE_SERVICES_DONNEES = {
  chapitre_1: "vos_donnees_energetiques",
  chapitre_2: "visualiser_vos_mesures",
  niveau_2: "5",
  page: "bouton_autres_services_de_donnees",
  type: "action",
};

export const CLICK_MODIFIER_SELECTION_VISU = {
  chapitre_1: "vos_donnees_energetiques",
  chapitre_2: "visualiser_vos_mesures",
  niveau_2: "5",
  page: "bouton_modifier_la_selection",
  type: "action",
};

export const CLICK_LANCER_PARCOURS = {
  chapitre_1: "page_accueil",
  niveau_2: "5",
  page: "lancer_le_parcours_guide_depuis_carrousel",
  type: "action",
};

export const CLICK_AIDE_ET_CONTACT = {
  chapitre_1: "page_accueil",
  niveau_2: "5",
  page: "raccourci_aide_et_contact",
  type: "action",
};

export const PARCOURS_GUIDE = {
  chapitre_1 : "accueil_premiere_connexion",
  chapitre_2 : "parcours_guide",
  chapitre_3 : "etape_0",
  niveau_2: "5",
  page : "page_lancement_du_parcours_guide"
};

export const PAGE_CLICK_GESTION_DE_VOTRE_ENTREPRISE = {
  chapitre_1 : "accueil_premiere_connexion",
  chapitre_2 : "parcours_guide",
  chapitre_3 : "etape_1",
  niveau_2: "5",
  page : "page_cliquez_sur_gestion_de_votre_entreprise"
};

export const CLICK_GESTION_DE_VOTRE_ENTREPRISE = {
  chapitre_1 : "accueil_premiere_connexion",
  chapitre_2 : "parcours_guide",
  chapitre_3 : "etape_1",
  niveau_2: "5",
  page : "mise_en_avant_menu_gestion_de_votre_entreprise",
  type : "action",
};

export const PAGE_FERMETURE_DU_PARCOURS_GUIDE = {
  chapitre_1 : "accueil_premiere_connexion",
  chapitre_2 : "parcours_guide",
  niveau_2: "5",
  page : "fin_anticipee_parcours_guide"
};


export const CLICK_REPRENDRE_LE_TUTORIEL = {
  chapitre_1 : "accueil_premiere_connexion",
  chapitre_2 : "parcours_guide",
  niveau_2: "5",
  page : "reprendre_le_tutoriel",
  type : "action",
};

export const PAGE_CLICK_VOS_DONNEES_ENERGETIQUES = {
  chapitre_1 : "accueil_premiere_connexion",
  chapitre_2 : "parcours_guide",
  chapitre_3 : "etape_2",
  niveau_2: "5",
  page : "page_cliquez_sur_vos_donnees_energetiques",
};

export const CLICK_VOS_DONNEES_ENERGETIQUES = {
  chapitre_1 : "accueil_premiere_connexion",
  chapitre_2 : "parcours_guide",
  chapitre_3 : "etape_2",
  niveau_2: "5",
  page : "mise_en_avant_menu_vos_donnees_energetiques",
  type : "action",
};

export const PAGE_CLICK_VOS_DOCUMENTS = {
  chapitre_1 : "accueil_premiere_connexion",
  chapitre_2 : "parcours_guide",
  chapitre_3 : "etape_3",
  niveau_2: "5",
  page : "page_cliquez_sur_vos_documents",
};

export const CLICK_VOS_DOCUMENTS = {
  chapitre_1 : "accueil_premiere_connexion",
  chapitre_2 : "parcours_guide",
  chapitre_3 : "etape_3",
  niveau_2: "5",
  page : "mise_en_avant_menu_vos_documents",
  type : "action",
};

export const PAGE_CLICK_VOS_PROJETS_RACCORDEMENT = {
  chapitre_1 : "accueil_premiere_connexion",
  chapitre_2 : "parcours_guide",
  chapitre_3 : "etape_4",
  niveau_2: "5",
  page : "page_cliquez_sur_votre_projet_raccordement",
};

export const CLICK_VOS_PROJETS_RACCORDEMENT = {
  chapitre_1 : "accueil_premiere_connexion",
  chapitre_2 : "parcours_guide",
  chapitre_3 : "etape_4",
  niveau_2: "5",
  page : "mise_en_avant_menu_votre_projet_raccordement",
  type : "action",
};

export const PAGE_CLICK_GESTION_DE_COMPTE = {
  chapitre_1 : "accueil_premiere_connexion",
  chapitre_2 : "parcours_guide",
  chapitre_3 : "etape_5",
  niveau_2: "5",
  page : "page_cliquez_sur_gestion_de_compte",
};

export const CLICK_GESTION_DE_COMPTE = {
  chapitre_1 : "accueil_premiere_connexion",
  chapitre_2 : "parcours_guide",
  chapitre_3 : "etape_5",
  niveau_2: "5",
  page : "mise_en_avant_menu_gestion_de_compte",
  type : "action",
};

export const PAGE_FONCTIONNALITES = {
  chapitre_1 : "accueil_premiere_connexion",
  chapitre_2 : "parcours_guide",
  chapitre_3 : "etape_6",
  niveau_2: "5",
  page : "mise_en_avant_fonctionnalites",
};

export const PAGE_GUIDES_UTILISATEURS = {
  chapitre_1 : "accueil_premiere_connexion",
  chapitre_2 : "parcours_guide",
  chapitre_3 : "etape_7",
  niveau_2: "5",
  page : "mise_en_avant_tutoriels_disponibles",
};

export const PAGE_FIN_PARCOURS = {
  chapitre_1 : "accueil_premiere_connexion",
  chapitre_2 : "parcours_guide",
  chapitre_3 : "etape_8",
  niveau_2: "5",
  page : "fin_du_parcours_guide",
};

export const CLICK_FIN_PARCOURS = {
  chapitre_1 : "accueil_premiere_connexion",
  chapitre_2 : "parcours_guide",
  chapitre_3 : "etape_8",
  niveau_2: "5",
  page : "clic_acceder_a_mon_compte",
  type : "action",
};

export const FERMETURE_PARCOURS = (numMenu) => ({
  chapitre_1 : "accueil_premiere_connexion",
  chapitre_2 : "parcours_guide",
  chapitre_3 : "etape_"+ numMenu,
  niveau_2: "5",
  page : "fermer_parcours_guide",
  type : "action",
});

export const EXIT_PARCOURS = (numMenu) => ({
  chapitre_1 : "accueil_premiere_connexion",
  chapitre_2 : "parcours_guide",
  chapitre_3 : "etape_"+ numMenu,
  niveau_2: "5",
  page : "j_ai_compris",
  type : "action",
});

export const CLICK_CONDITIONS_GENERALES_LINK = {
  chapitre_1: "vos_documents",
  chapitre_2: "contrats_et_avenants",
  chapitre_3: "parcours_acces_autorise",
  page: "redirection_enedis_documentation_de_référence_via_popin",
}

export const MODAL_CONDITIONS_GENERALES = {
  chapitre_1: "vos_documents",
  chapitre_2: "contrats_et_avenants",
  chapitre_3: "parcours_acces_autorise",
  page: "popin_voir_les_references",
}

export const FERMETURE_MODAL_CONDITIONS_GENERALES = {
  chapitre_1: "vos_documents",
  chapitre_2: "contrats_et_avenants",
  chapitre_3: "parcours_acces_autorise",
  page: "croix_popin_voir_les_references",
}

export const POPIN_DESACTIVER_LA_COLLECTE = {
  chapitre_1: "vos_donnees_energetiques",
  chapitre_2: "gerer_la_collecte_de_courbe_de_charge",
  page: "popin_desactiver_la_collecte",
}

export const POPIN_DESACTIVER_LA_COLLECTE_ANNULER = {
  chapitre_1: "vos_donnees_energetiques",
  chapitre_2: "gerer_la_collecte_de_courbe_de_charge",
  page: "popin_desactiver_la_collecte_annuler",
  type: "action",
}

export const POPIN_DESACTIVER_LA_COLLECTE_CONFIRMER = {
  chapitre_1: "vos_donnees_energetiques",
  chapitre_2: "gerer_la_collecte_de_courbe_de_charge",
  page: "popin_desactiver_la_collecte_confirmer",
  type: "action",
};

export const POPIN_RESILIER_LES_SERVICES_ASSOCIES_AU_TIERS = {
  chapitre_1: "vos_donnees_energetiques",
  chapitre_2: "gerer_le_partage_des_donnees_en_masse",
  page: "popin_resilier_les_services_associes_au_tiers"
}

export const ANNULER_DEMANDE_RESILIATION = {
  chapitre_1: "vos_donnees_energetiques",
  chapitre_2: "gerer_le_partage_des_donnees_en_masse",
  page: "annuler_demande_resiliation",
  type: "action",
}

export const CONFIRMER_LA_RESILIATION_DES_SERVICES = {
  chapitre_1: "vos_donnees_energetiques",
  chapitre_2: "gerer_le_partage_des_donnees_en_masse",
  page: "confirmer_la_resiliation_des_services",
  type: "action",
}

export const TOAST_VOUS_NETES_PAS_HABILITE_A_RESILIER = {
  chapitre_1: "vos_donnees_energetiques",
  chapitre_2: "gerer_le_partage_des_donnees_en_masse",
  page: "toast_vous_netes_pas_habilite_resilier_tiers",
}
export const POP_IN_REMPLACEMENT_INTERLOCUTEUR = {
  chapitre_1: "gestion_de_votre_entreprise",
  chapitre_2: "vos_interlocuteurs",
  chapitre_3: "voir_interlocuteur",
  page: "remplacement_interlocuteur",
}

export const CLICK_ANNULER_POP_IN_REMPLACEMENT_INTERLOCUTEUR = {
  chapitre_1: "gestion_de_votre_entreprise",
  chapitre_2: "vos_interlocuteurs",
  chapitre_3: "voir_interlocuteur",
  page: "annuler​_remplacement",
  type: "action"
}
export const CLICK_CONFIRMER_POP_IN_REMPLACEMENT_INTERLOCUTEUR = {
  chapitre_1: "gestion_de_votre_entreprise",
  chapitre_2: "vos_interlocuteurs",
  chapitre_3: "voir_interlocuteur",
  page: "confirmer_remplacement",
  type: "action"
}

export const POP_IN_SUPPRIMER_INTERLOCUTEUR = {
  chapitre_1: "gestion_de_votre_entreprise",
  chapitre_2: "vos_interlocuteurs",
  chapitre_3: "voir_interlocuteur",
  page: "suppression_interlocuteur",
}

export const CLICK_ANNULER_POP_IN_SUPPRIMER_INTERLOCUTEUR = {
  chapitre_1: "gestion_de_votre_entreprise",
  chapitre_2: "vos_interlocuteurs",
  chapitre_3: "voir_interlocuteur",
  page: "annuler_suppression",
  type: "action"
}
export const CLICK_CONFIRMER_POP_IN_SUPPRIMER_INTERLOCUTEUR = {
  chapitre_1: "gestion_de_votre_entreprise",
  chapitre_2: "vos_interlocuteurs",
  chapitre_3: "voir_interlocuteur",
  page: "confirmer_suppression",
  type: "action"
}

export const ERROR_CHARGEMENT_PAGE = {
  chapitre_1: "gestion_de_votre_entreprise",
  chapitre_2: "vos_interlocuteurs",
  page: "erreur_technique_survenue"
}
