/**
 * Composant pour le toast
 */
export default class NeufsneufToast extends HTMLElement {

  /**
   * Singleton
   * @type {NeufsneufToast} l'insance du singleton
   */
  static INSTANCE = null;

  /**
   * Constructeur du toast
   */
  constructor() {
    super();
    NeufsneufToast.INSTANCE = this;

    this.toast = this.querySelector("lnc-toast");
  }

  /**
   * Affiche un nouveau toast avec les valeur par défaut
   * @param message {string} Message affiché dans le toast
   */
  toastme(message) {
    this.setToast(message, "true", 5000, "m", "", "", "");
    this.show();
  }

  /**
   * Paramétrage du toast
   * @param message Message affiché dans le toast
   * @param timeDisplay Temps d'affichage du toast en millisecondes (doit être > 0)
   * @param icon Icône affichée dans le toast
   * @param isCloseable Affiche l'icône de fermeture ('true' ou 'false')
   * @param size Taille du toast ('s' ou 'm')
   * @param textColor Couleur du texte (si vide, vaut blanc)
   * @param backgroundColor Couleur de fond (si vide, vaut neutre-700)
   */
  setToast(message, isCloseable, timeDisplay, size, icon, textColor, backgroundColor) {
    this.setToastMessage(message);
    this.setToastIsCloseable(isCloseable);
    this.setToastTimeDisplay(timeDisplay);
    this.setToastSize(size);
    this.setToastIcon(icon);
    this.setToastTextColor(textColor);
    this.setToastBackgroundColor(backgroundColor);
  }

  /**
   * Paramétrage du message
   * @param message
   */
  setToastMessage(message) {
    if (typeof message === "string") {
      this.toast.setAttribute("message", message);
    }
  }

  /**
   * Paramétrage du temps d'affichage (doit être supérieur à 0)
   * @param timeDisplay
   */
  setToastTimeDisplay(timeDisplay) {
    if (timeDisplay && parseInt(timeDisplay) > 0) {
      this.toast.setAttribute("time-display", timeDisplay);
    }
  }

  /**
   * Paramétrage de l'icône
   * @param icon
   */
  setToastIcon(icon) {
    if (icon && typeof icon === "string") {
      this.toast.setAttribute("icon", icon);
    } else {
      this.toast.removeAttribute("icon");
    }
  }

  /**
   * Paramétrage de l'icône de fermeture ('true' ou 'false')
   * @param isCloseable
   */
  setToastIsCloseable(isCloseable) {
    this.toast.setAttribute("is-closeable", isCloseable || "false");
  }

  /**
   * Paramétrage de la taille (s ou m)
   * @param size
   */
  setToastSize(size) {
    if (size === "s" || size === "m") {
      this.toast.setAttribute("size", size);
    } else {
      this.toast.setAttribute("size", "s");
    }
  }

  /**
   * Paramétrage de la couleur du texte
   * Par défaut : blanc
   * @param textColor
   */
  setToastTextColor(textColor) {
    if (textColor && typeof textColor === "string") {
      this.toast.setAttribute("text-color", textColor);
    } else {
      this.toast.setAttribute("text-color", "blanc");
    }
  }

  /**
   * Paramétrage de la couleur de fond
   * Par défaut : neutre-700
   * @param backgroundColor
   */
  setToastBackgroundColor(backgroundColor) {
    if (backgroundColor && typeof backgroundColor === "string") {
      this.toast.setAttribute("background-color", backgroundColor);
    } else {
      this.toast.setAttribute("background-color", "neutre-700");
    }
  }

  /**
   * Affiche un nouveau toast
   */
  show() {
    this.toast.setAttribute("is-visible", "false");
    setTimeout(() => {
      this.toast.setAttribute("is-visible", "true");
    });
  }

  /**
   * Cache le toast
   */
  hide() {
    this.toast.setAttribute("is-visible", "false");
  }
}
