import MicroApp from "./micro-app-base";
import { monCompteClientService } from "../../api-client/index";
import { StoreManager } from "../../services/store-manager";

/**
 * Microapp compte client
 */
export default class CompteClientMicroApp extends MicroApp {
  /**
   * Surcharge pour réagir aux messages de la microapp
   */
  childPymEvents = {
    /**
     * Event pour mettre à jour les informations de la personne connectée
     */
    changementIdentifiantUser: function () {
      monCompteClientService.getUserInfosV2(StoreManager.cnAlex).then(response => {
        StoreManager.infoCompte = response;
      });
    },
  };

  /**
   * Initialise une nouvelle instance de type {@type CompteClientMicroApp}.
   */
  constructor() {
    super();
  }
}
